@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Allura&display=swap");
@import "./styles/maintenance";

$font-stack: "Merriweather", serif;
$primary-color: #242333;
$primary-sec-color: #191829;
$secondary-color: #01b0d3;
$text-color: #fff;

html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: $font-stack;
  background-color: $primary-color;
  color: $text-color;
}

li {
  list-style: none;
}

.container {
  width: 90%;
  margin: auto;
}

a {
  text-decoration: none;
  color: $text-color;
}

.btn {
  background-color: $secondary-color;
  color: $text-color;
  border: none;
  padding: 1rem 0.6rem;
  font-family: "Merriweather", serif;
  border-radius: 5px;
  cursor: pointer;
}

.hide {
  display: none;
}

.icons {
  margin-right: 1rem;
  width: 1.8rem;
  height: 1.8rem;
}

.icons:hover {
  fill: #8989ff;

  cursor: pointer;
}

// Navbar
nav {
  .container {
    height: 85px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-nav {
      h1 {
        font-family: "Allura", cursive;
        font-size: 2.5rem;
        cursor: pointer;
      }
    }

    .right-nav {
      display: flex;
      align-items: center;

      ul {
        display: flex;

        li {
          margin-right: 2.2rem;
          cursor: pointer;
        }
      }
    }
  }
}

// Home

.home {
  justify-content: center;
  display: flex;
  align-items: center;
  min-height: 90vh;

  .textContent {
    width: 38%;
    h2 {
      font-size: 2.2rem;
      font-weight: 900;
    }

    p {
      font-weight: 200;
      margin: 0.8rem 0;
      line-height: 1.5;
      width: 70%;
    }

    .download {
      background: $secondary-color;
      color: $text-color;
      padding: 1rem 0.6rem;
      border-radius: 6px;
    }
  }
  .ImageContent {
    img {
      width: 18rem;
      height: 100%;
      object-fit: cover;
      border-radius: 100%;
    }
  }
}

// About
.about {
  .container {
    padding: 0 3rem;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3.5rem;

    h1 {
      text-align: center;
    }

    .aboutSection {
      display: flex;
      gap: 5rem;
      height: 100%;
      justify-content: center;

      .left-img {
        img {
          width: 32rem;
          height: 100%;
          object-fit: cover;
        }
      }

      .right-content {
        .upperText {
          p {
            margin: 1rem 0;
            line-height: 1.8;
            font-weight: 100;
          }
        }

        .downText {
          h3 {
            margin-bottom: 1rem;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

// Project

.wrapper-project {
  background: $primary-sec-color;
  .container {
    .project {
      min-height: 100vh;
      padding: 3rem 0;

      h1 {
        padding: 3rem 0;
        text-align: center;
      }
      .project-card {
        display: grid;
        place-items: center;
        grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
      }

      .button {
        margin: 2rem 0;
        display: flex;
        justify-content: center;

        .btn {
          padding: 1 1.2rem;
          cursor: pointer;
        }
      }
    }
  }
}

// Project card
.card {
  background-color: $primary-sec-color;
  border-radius: 8px;
  margin-bottom: 1.8rem;
  box-shadow: 5px 4px 10px -4px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 5px 4px 10px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 5px 4px 10px -4px rgba(0, 0, 0, 0.75);

  .card-img {
    img {
      width: 22rem;
      height: 15rem;
      object-fit: cover;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }

  .card-title {
    padding: 1.5rem 0;
    text-align: center;
    color: $text-color;

    h3 {
      font-weight: 100;
    }
  }
}

// Contact

.wrapper {
  background: $primary-sec-color;
  .contact {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6rem;
    min-height: 100vh;

    .form-section {
      h2 {
        margin-bottom: 1.3rem;
      }

      form {
        .first {
          display: flex;
          gap: 6rem;

          .formLabel {
            display: flex;
            flex-direction: column;

            input {
              width: 300px;
              height: 40px;
              border: none;
              border-bottom: 3px solid #fff;
              padding: 0.3rem;
              margin-top: 0.5rem;
              background: none;
              font-family: "Merriweather", serif;
              color: $text-color;

              &:focus {
                outline: none;
                color: $text-color;
              }

              &::placeholder {
                font-family: "Merriweather", serif;
                color: #c4c4c4;
              }
            }
          }
        }

        .Message {
          margin: 2rem 0;

          textarea {
            resize: none;
            width: 700px;
            height: 160px;
            background: none;
            border: none;
            border-bottom: 3px solid #fff;
            padding: 0.3rem;
            font-family: "Merriweather", serif;
            color: $text-color;

            &:focus {
              color: $text-color;
              outline: none;
              font-family: "Merriweather", serif;
            }

            &::placeholder {
              font-family: "Merriweather", serif;
              color: #c4c4c4;
            }
          }
        }

        .right-button {
          display: flex;
          justify-content: flex-end;

          .btn {
            padding: 1rem;
          }
        }
      }
    }

    .Info-section {
      .info {
        .infos {
          margin-bottom: 1rem;

          h4 {
            color: $secondary-color;
            margin-bottom: 0.3rem;
            font-weight: 100;
          }

          p {
            font-weight: 100;
          }
        }
      }
    }
  }
}

.mobile {
  display: none;
}

::-webkit-scrollbar-track {
  border: 5px solid white;
  background-color: #b2bec3;
}

::-webkit-scrollbar {
  width: 15px;
  background-color: #dfe6e9;
}

::-webkit-scrollbar-thumb {
  background-color: $secondary-color;
  border-radius: 10px;
}

.top-to-btm {
  position: relative;
}

.icon-position {
  position: fixed;
  bottom: 20px;
  right: 25px;
  z-index: 20;
}

.icon-style {
  background-color: $secondary-color;
  border: 2px solid #fff;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: #fff;
  cursor: pointer;
}

@media (max-width: 798px) {
  .mobile {
    display: block;
    min-height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
  }

  .home,
  .about,
  .project,
  nav {
    display: none;
  }

  .wrapper {
    display: none;
  }
}
