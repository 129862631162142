.main {
  &__container {
    height: 600px;
    width: 100%;

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      text-align: center;
      line-height: 2;
    }
  }

  &__img {
    height: 250px;
    object-fit: cover;
  }
}
